<template>
    <div>
      <iframe id="captcha-frame" ref="captchaFrame" :srcdoc="html"></iframe>
      <b-button @click="reload" class="reload">
        <b-icon-arrow-clockwise/>
      </b-button>
    </div>
</template>
<script>
/* 
This component wraps the captcheck captcha, hosted at https://captcheck.extinctionrebellion.uk/

Captcheck is designed to be included in a form - it adds a session code and the selected answer to the
form values.

The captcheck script adds the captcha to the element with the captcheck_container class. It does this
in the window.load event.

To use the captcha from Vue we need programatic access to the form values. We can get this
by adding event listeners to the captcha's elements. Because the captcha is added to the document
after is has loaded, we add the event listeners in a mutation observer which is triggered when child
elements are added to the form.
*/
import { BIconArrowClockwise } from 'bootstrap-vue'

export default {
  name: "CaptcheckCaptcha",
  components: {BIconArrowClockwise},
  data: function () {
    return {
      captcha: {
        sessionId: null,
        answer: null,
      },
      // eslint-disable-next-line
      html: '<script src="https://captcheck.extinctionrebellion.uk/captcheck.min.js"><\/script><form id="captcha-form"><div class="captcheck_container" /></form>',
    };
  },
  mounted() {
    setTimeout(() => this.getAnswers(), 200);
  },
  methods: {
    getAnswers() {
      const frame = document.getElementById("captcha-frame");
      if (frame) {
        const form = frame.contentWindow.document.getElementById("captcha-form");
        if (form) {
          const sessionId = form.captcheck_session_code ? form.captcheck_session_code.value : null;
          const answer = form.captcheck_selected_answer ? form.captcheck_selected_answer.value : null;

          if (sessionId != this.captcha.sessionId || answer != this.captcha.answer) {
            this.raiseInputEvent(sessionId, answer);
          }
        }
      }
      setTimeout(() => this.getAnswers(), 200);
    },
    raiseInputEvent(sessionId, answer) {
      this.captcha.sessionId = sessionId;
      this.captcha.answer = answer;
      this.$emit("input", this.captcha);
    },
    reload() {
      document.getElementById("captcha-frame").srcdoc = this.html;
      this.captcha.sessionId = null;
      this.captcha.answer = null;
      this.$emit("input", this.captcha);
    },
  },
};
</script>

<style scoped>
iframe {
  border: none
}
button.reload {
  vertical-align: top;
  margin-top: 38px !important;
}
</style>