<template>
  <autocomplete
    placeholder="Station name"
    :search="searchStations"
    auto-select 
    @submit="selectStation"
    @blur="handleBlur"
  ></autocomplete>
</template>
<script>
import FuzzySearch from "fuzzy-search";

export default {
  props: ["value"],
  data() {
    return {
      stationSearcher: null,
    };
  },
  methods: {
    async searchStations(input) {
      if (input.length < 1) {
        return [];
      }
      if (this.stationSearcher === null) {
        var stationList = await this.$http.get("/api/v1/stations");
        this.stationSearcher = function (station) {
          const searcher = new FuzzySearch(stationList.data, [], {
            sort: true,
          });
          const results = searcher.search(station);
          if (results.length) {
            return results;
          }
          return [station];
        };
      }

      return this.stationSearcher(input);
    },
    selectStation(station) {
      this.$emit("input", station);
    },
    handleBlur(event) {
      this.selectStation(event.target.value)
    }
  },
  mounted: function () {
    // Increase the z-index on the dropdown so it is displayed on top of other elements.
    // The z-index is set inline, so we can't override it with a style.
    const elements = document.getElementsByClassName(
      "autocomplete-result-list"
    );
    if (elements[0]) {
      elements[0].style.zIndex = "5";
    }
  },
};
</script>

